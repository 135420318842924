<div id="">
  <div class="container">
    <!-- <div class="row text-center">
      <h2 class="bold">About</h2>
    </div> -->
    <div class="row">
      <div class="col-md-5 animate-box">
        <div class="">
          <!-- <div class="item">
            <img class="img-responsive about-img" src="../assets/images/about.jpg"
              alt="html5 bootstrap template by colorlib.com">
          </div> -->
          <div class="item" style="margin-top: 10vh">
            <img class="img-responsive about-img" src="../assets/images/about.jpg"
              alt="html5 bootstrap template by colorlib.com">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-md-push-1 animate-box">
        <div class="about-desc">
          <div class="">
            <!-- <div class="item">
              <h2><span>Abhishek</span><span>Kumar</span></h2>
            </div> -->
            <div class="item">
              <h2><span>Full Stack Developer</span></h2>
            </div>
          </div>
          <div class="desc">
            <div class="rotate">
              <h2 class="heading">About</h2>
            </div>
            <p>
              My name is Abhishek Kumar. I design, develop and maintain end-to-end workflow for business platforms and applications.
I don't like to define myself by the work I've done. I define myself by the work I want to do. I prefer to keep learning , continue challenging myself, and interseting things that matter.
I work to make a better web: one that is fast, easy to use, beautiful, accessible to all, and frustration-free.
You can also find my around pune for trekking. <br>
Feel free to Connect or Follow me on <a href="https://www.linkedin.com/in/abhishek-kumar-b19a4ba1/">Linkedin</a>.
            </p>
            <!-- <p class="colorlib-social-icons">
              <a href="#"><i class="icon-facebook4"></i></a>
              <a href="#"><i class="icon-twitter3"></i></a>
              <a href="#"><i class="icon-googleplus"></i></a>
              <a href="#"><i class="icon-dribbble2"></i></a>
            </p> -->
            <div style="display: flex;">
              <p><a href="https://abhiresume.s3.ap-south-1.amazonaws.com/Abhishek's+Resume+_7years_.pdf" class="btn btn-primary btn-outline" target="_blank">View Resume</a></p>
              <p><a routerLink="/contact" style="margin-left: 10px;" class="btn btn-primary btn-outline">Contact Me!</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
