<div id="colorlib-blog">
  <div class="container">
    <!-- <div class="row text-center">
      <h2 class="bold">Blog</h2>
    </div> -->
    <div class="row">
      <div class="col-md-12 col-md-offset-0 text-center animate-box intro-heading">
        <!-- <span>Blog</span> -->
        <h2>Read My Blog</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-2">
        <div class="rotate">
          <h2 class="heading">My Blog</h2>
        </div>
      </div>
    </div>
    <div class="row animate-box">
      <div class="owl-carousel1">
        <div class="item">
          <div class="col-md-12">
            <div class="article">
              <a href="blog.html" class="blog-img">
                <img style="height: 45vh;" class="img-responsive" src="assets/images/img-3.jpeg" alt="html5 bootstrap by colorlib.com">
                <div class="overlay"></div>
                <div class="link">
                  <span class="read"><h2>Read more</h2></span>
                </div>
              </a>
              <div class="desc">
                <span class="meta">15, Mar 2019</span>
                <h2><a href="blog.html">Dynamic UI Grid with Material</a></h2>
                <p>This will help user to automatically create UI grid from backend config.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="col-md-12">
            <div class="article">
              <a href="blog.html" class="blog-img">
                <img style="height: 45vh;" class="img-responsive" src="assets/images/img-2.png" alt="html5 bootstrap by colorlib.com">
                <div class="overlay"></div>
                <div class="link">
                  <span class="read"><h2>Read more</h2></span>
                </div>
              </a>
              <div class="desc">
                <span class="meta">20, Apr 2020</span>
                <h2><a href="blog.html">Dynamic API Creation</a></h2>
                <p>User can create API using form with whatever details they want for their field.
                  These api will help with normal CRUD.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="col-md-12">
            <div class="article">
              <a href="blog.html" class="blog-img">
                <img style="height: 45vh;" class="img-responsive" src="assets/images/about-2.jpg" alt="html5 bootstrap by colorlib.com">
                <div class="overlay"></div>
                <div class="link">
                  <span class="read"><h2>Read more</h2></span>
                </div>
              </a>
              <div class="desc">
                <span class="meta">04, Jul 2022</span>
                <h2><a href="blog.html">Why TREK</a></h2>
                <p>Why we need to get away for sometime.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
