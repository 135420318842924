<div id="colorlib-work">
  <div class="container">
    <!-- <div class="row text-center">
      <h2 class="bold">Works</h2>
    </div> -->
    <div class="row">
      <div class="col-md-12 col-md-offset-0 text-center animate-box intro-heading">
        <!-- <span>Portfolio</span> -->
        <h2>My Projects</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="rotate">
          <h2 class="heading">Portfolio</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="work-entry animate-box">
          <a href="https://pocketkhata.com" target="_blank" class="work-img" style="background-image: url(../assets/images/work-1.png);">
            <div class="display-t">
              <div class="work-name">
                <h2>Pocketkhata</h2>
              </div>
            </div>
          </a>
          <div class="col-md-4 col-md-offset-4">
            <div class="desc">
              <p>This is a accounting software for wholesale business. In this software user can add
                bills and payments of their customer as well as company and see different type of
                stats for their account details.
              </p>
              <p class="read"><a href="#">View details</a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="work-entry animate-box">
          <a href="https://awzing.vercel.app" target="_blank" class="work-img" style="background-image: url(../assets/images/work-2.png);">
            <div class="display-t">
              <div class="work-name">
                <h2>Awzing</h2>
              </div>
            </div>
          </a>
          <div class="col-md-4 col-md-offset-4">
            <div class="desc">
              <p>This is a private Pharma company website which demonstrate the products and
                page to contact for more queries.</p>
              <p class="read"><a href="#">View Details</a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="work-entry animate-box">
          <a href="https://e-pharmacy-frontend.vercel.app/home" target="_blank" class="work-img" style="background-image: url(../assets/images/work-3.png);">
            <div class="display-t">
              <div class="work-name">
                <h2>E-Pharmacy</h2>
              </div>
            </div>
          </a>
          <div class="col-md-4 col-md-offset-4">
            <div class="desc">
              <p>This is E-Pharmacy website which will help to order product.
                It has features of cart and user management with order history details
                also help user to get exact time of delivery with the help of machine learning.
              </p>
              <p class="read"><a href="#">View details</a></p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-12">
        <div class="work-entry animate-box">
          <a href="work.html" class="work-img" style="background-image: url(../assets/images/work-4.png);">
            <div class="display-t">
              <div class="work-name">
                <h2>Restaurant</h2>
              </div>
            </div>
          </a>
          <div class="col-md-4 col-md-offset-4">
            <div class="desc">
              <p>This is under development website for restaurant business.</p>
              <p class="read"><a href="#">View details</a></p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
