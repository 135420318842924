<body>

  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div style="display: flex;">
        <div class="navbar-header">
          <a class="navbar-brand" href="/">About Me!</a>
        </div>
        <!-- <div class="other-section" style="float: right;">
          <a class="navbar-brand" routerLink="/blog" style="font-size: 16px !important;">Blog</a>
        </div> -->
      </div>

    </div>
  </nav>

  <div>
    <router-outlet></router-outlet>
    <!-- <app-about></app-about>
    <app-skill></app-skill>
    <app-work></app-work> -->
    <!-- <app-blog></app-blog> -->
    <hr>
    <footer>
      <div id="footer">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-pb-sm">
              <div class="row">
                <div class="col-md-10">
                  <h2>My Quote</h2>
                  <p>A dream doesn't become reality through magic; it takes sweat, determination and hard work.</p>
                  <p><a href="#">abhi.omex@outlook.com</a></p>
                  <!-- <p class="colorlib-social-icons">
                    <a href="#"><i class="icon-facebook4"></i></a>
                    <a href="#"><i class="icon-twitter3"></i></a>
                    <a href="#"><i class="icon-googleplus"></i></a>
                    <a href="#"><i class="icon-dribbble2"></i></a>
                  </p> -->
                </div>
              </div>
            </div>
            <div class="col-md-4 col-pb-sm">
              <h2>Latest Blog</h2>
              <div class="f-entry">
                <a href="#" class="featured-img" style="background-image: url(../assets/images/img-1.jpg);"></a>
                <div class="desc">
                  <span>15, Mar 2019</span>
                  <h3><a href="#">Dynamic UI Grid with Material</a></h3>
                </div>
              </div>
              <div class="f-entry">
                <a href="#" class="featured-img"style="background-image: url(../assets/images/img-2.jpg);"></a>
                <div class="desc">
                  <span>20, Apr 2020</span>
                  <h3><a href="#">Dynamic API Creation</a></h3>
                </div>
              </div>
              <div class="f-entry">
                <a href="#" class="featured-img" style="background-image: url(../assets/images/img-3.jpg);"></a>
                <div class="desc">
                  <span>04, Jul 2022</span>
                  <h3><a href="#">Why TREK</a></h3>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-pb-sm">
              <h2>Newsletter</h2>
              <p>The future belongs to those who believe in the beauty of their dreams.</p>
              <div class="subscribe text-center">
                <div class="form-group">
                  <input type="text" class="form-control text-center" placeholder="Enter Email address">
                  <input type="submit" value="Subscribe" class="btn btn-primary btn-custom">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

  </div>

</body>
