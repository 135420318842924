<div id="colorlib-services">
  <div class="container">
    <!-- <div class="row text-center">
      <h2 class="bold">Skills</h2>
    </div> -->
    <div class="row">
      <div class="col-md-12">
        <div class="services-flex">
          <div class="one-third">
            <div class="row">
              <div class="col-md-12 col-md-offset-0 animate-box intro-heading">
                <span>My Skills</span>
                <h2>Here Are Some of My Skills</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="rotate">
                  <h2 class="heading">Services</h2>
                </div>
              </div>
              <div class="col-md-6">
                <div class="services animate-box">
                  <h3>1 - Front End Development</h3>
                  <ul>
                    <li>HTML / CSS</li>
                    <li>Javascript</li>
                    <li>Angular</li>
                    <li>React</li>
                    <li>Ember</li>
                  </ul>
                </div>
                <div class="services animate-box">
                  <h3>2 - Backend Development</h3>
                  <ul>
                    <li>Node Js</li>
                    <li>Go</li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <div class="services animate-box">
                  <h3>3 - Database</h3>
                  <ul>
                    <li>Sql</li>
                    <li>MongoDB</li>
                    <li>Postgres</li>
                    <li>Redis</li>
                    <li>Elastic Search</li>
                  </ul>
                </div>
                <div class="services animate-box">
                  <h3>4 - AWS</h3>
                  <ul>
                    <li>EC2</li>
                    <li>WAF</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="one-forth services-img" style="background-image: url(../assets/images/fullstack.jpeg);">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
